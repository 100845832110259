@import "config/_config";

.col_2{
	& > *{
		width:48%;

		@include mq-down(){
			width: 100%;
			margin-bottom: 1rem;
		}
	}

	@include mq-down(){
		figure{
			order:1;
		}

		.txt{
			order:2;
		}
	}
}

.col_3{
	& > *{
		width:calc(100% / 3.1);

		@include mq-down(){
			width: 100%;
			margin-bottom: 1rem;
		}
	}
}


.col_4{
	& > *{
		width:calc(100% / 4.1);

		@include mq-down(){
			width: 100%;
			margin-bottom: 1rem;
		}
	}
}


.col_5{
	& > *{
		width:calc(100% / 5.1);

		@include mq-down(){
			width: 100%;
			margin-bottom: 1rem;
		}
	}
}



.col_2_1{

	& > *:nth-of-type(1){
		width:68%;
		@include mq-down(){
			width: 100%;
			margin-bottom: 1rem;
		}
	}

	& > *:nth-of-type(2){
		width:31%;
		@include mq-down(){
			width: 100%;
			margin-bottom: 1rem;
		}
	}

	figure{
		text-align: center;
	}


	@include mq-down(){
		.txt{
			order:2;
			margin-top: 2rem;
		}

		.photo{
			order:1;
		}
	}
}


.col_2_1 > *:nth-of-type(1){
	width:68%;
	@include mq-down(){
		width: 100%;
		margin-bottom: 1rem;
	}
}

.col_2_1 > *:nth-of-type(2){
	width:31%;
	@include mq-down(){
		width: 100%;
		margin-bottom: 1rem;
	}
}


.col_1_2 > *:nth-of-type(1){
	width:31%;
	@include mq-down(){
		width: 100%;
		margin-bottom: 1rem;
	}
}

.col_1_2 > *:nth-of-type(2){
	width:68%;
	@include mq-down(){
		width: 100%;
		margin-bottom: 1rem;
	}
}