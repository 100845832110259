/* margin top */
.mt{
	&0 {
		margin-top: 0px!important;
	}

	&10 {
		margin-top: 10px!important;
	}

	&15 {
		margin-top: 15px!important;
	}

	&20 {
		margin-top: 20px!important;
	}

	&30 {
		margin-top: 30px!important;
	}

	&40 {
		margin-top: 40px!important;
	}

	&50 {
		margin-top: 50px!important;
	}

	&60 {
		margin-top: 60px!important;
	}

	&70 {
		margin-top: 70px!important;
	}

	&80 {
		margin-top: 80px!important;
	}

	&90 {
		margin-top: 90px!important;
	}
}

/* margin bottom */
.mb{
	&0 {
		margin-bottom: 0px!important;
	}

	&10 {
		margin-bottom: 10px!important;
	}

	&15 {
		margin-bottom: 15px!important;
	}

	&20 {
		margin-bottom: 20px!important;
	}

	&30 {
		margin-bottom: 30px!important;
	}

	&40 {
		margin-bottom: 40px!important;
	}

	&50 {
		margin-bottom: 50px!important;
	}

	&60 {
		margin-bottom: 60px!important;
	}

	&70 {
		margin-bottom: 70px!important;
	}

	&80 {
		margin-bottom: 80px!important;
	}

	&90 {
		margin-bottom: 90px!important;
	}
}
