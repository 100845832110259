$blue:  #083071;
$orange:#e96c04;

$green: #36a26c;
$black: #313131;
$white: #fff;

$s-blue:  #def3fd;
$d-blue:  #003f62;
$gray:   #565656;
$l-gray:   #ececec;
$sBlue:  #ebf7ff;
$pink:#f06373;
$red:   #fe6262;

$contents-width:1200px;

$sp : 768;
$tablet:1000;