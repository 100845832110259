.blog.detail{
	.date{
		display: block;
		margin-bottom: 3rem;
		color:#666;
		text-align: right;
	}

	.ttl-border_twocolor{
		margin-top: 0;
		font-size: 2.5rem;
	}


	.editor-block{
		margin-top: 2rem;
		padding: 3rem;
		background: #fff;
		p {
			font-size: 16px;
			font-size: 1.6rem;
			margin-bottom: 1em;
		 }

		.aligncenter {
			display: block;
			margin: 5px auto 20px auto;
		}

		.alignright {
			float: right;
			margin: 5px 0 20px 5%;
		}

		.alignleft {
			float: left;
			margin: 5px 5% 20px 0;
		}

		@include mq-down(){
			p {
				font-size: 13px;
				font-size: 1.3rem;
				margin-bottom: .5em;
			}
			img {
				width: 100%;
			}
			.alignright {
				float: none;
				margin: 1em 0;
			}
			.alignleft {
				float: none;
				margin: 1em 0;
			}
		}
	}



}