@charset "UTF-8";
/* # =================================================================
	 # Global selectors
	 # ================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
html {
  box-sizing: border-box;
  overflow-y: scroll;
  /* All browsers without overlaying scrollbars */
  -webkit-text-size-adjust: 100%;
  /* iOS 8+ */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  font-family: "Noto Sans Japanese",'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
  box-sizing: border-box;
}

::before,
::after {
  text-decoration: inherit;
  /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

* {
  padding: 0;
  /* Reset `padding` and `margin` of all elements */
  margin: 0;
}

/* # =================================================================
	 # General elements
	 # ================================================================= */
/* Add the correct display in iOS 4-7.*/
audio:not([controls]) {
  display: none;
  height: 0;
}

hr {
  overflow: visible;
  /* Show the overflow in Edge and IE */
}

/*
* Correct `block` display not defined for any HTML5 element in IE 8/9
* Correct `block` display not defined for `details` or `summary` in IE 10/11
* and Firefox
* Correct `block` display not defined for `main` in IE 11
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

summary {
  display: list-item;
  /* Add the correct display in all browsers */
}

small {
  font-size: 80%;
  /* Set font-size to 80% in `small` elements */
}

[hidden],
template {
  display: none;
  /* Add the correct display in IE */
}

abbr[title] {
  border-bottom: 1px dotted;
  /* Add a bordered underline effect in all browsers */
  text-decoration: none;
  /* Remove text decoration in Firefox 40+ */
}

a {
  background-color: transparent;
  /* Remove the gray background on active links in IE 10 */
  -webkit-text-decoration-skip: objects;
  /* Remove gaps in links underline in iOS 8+ and Safari 8+ */
  text-decoration: none;
}

a:active,
a:hover {
  outline-width: 0;
  /* Remove the outline when hovering in all browsers */
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* Specify the font family of code elements */
}

b,
strong {
  font-weight: bolder;
  /* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}

dfn {
  font-style: italic;
  /* Address styling not present in Safari and Chrome */
}

/* Address styling not present in IE 8/9 */
mark {
  background-color: #ff0;
  color: #000;
}

/* https://gist.github.com/unruthless/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

/* # =================================================================
	 # Forms
	 # ================================================================= */
input {
  border-radius: 0;
}

/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
}

input[type="submit"] {
  -webkit-appearance: none;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

[type="number"] {
  width: auto;
  /* Firefox 36+ */
}

[type="search"] {
  -webkit-appearance: textfield;
  /* Safari 8+ */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Safari 8 */
}

textarea {
  overflow: auto;
  /* Internet Explorer 11+ */
  resize: vertical;
  /* Specify textarea resizability */
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* Specify font inheritance of form elements */
}

optgroup {
  font-weight: bold;
  /* Restore the font weight unset by the previous rule. */
}

button {
  overflow: visible;
  /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS */
}

button,
select {
  text-transform: none;
  /* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
}

/* Style select like a standard input */
select {
  -moz-appearance: none;
  /* Firefox 36+ */
  -webkit-appearance: none;
  /* Chrome 41+ */
}

select::-ms-expand {
  display: none;
  /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor;
  /* Internet Explorer 11+ */
}

legend {
  border: 0;
  /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit;
  /* Correct the color inheritance from `fieldset` elements in IE */
  display: table;
  /* Correct the text wrapping in Edge and IE */
  max-width: 100%;
  /* Correct the text wrapping in Edge and IE */
  white-space: normal;
  /* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari */
  font: inherit;
  /* Change font properties to `inherit` in Chrome and Safari */
}

[type="search"] {
  -webkit-appearance: textfield;
  /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px;
  /* Correct the outline style in Safari */
}

/* # =================================================================
	 # Specify media element style
	 # ================================================================= */
img {
  border-style: none;
  /* Remove border when inside `a` element in IE 8/9/10 */
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera */
progress {
  vertical-align: baseline;
}

svg:not(:root) {
  overflow: hidden;
  /* Internet Explorer 11- */
}

audio,
canvas,
progress,
video {
  display: inline-block;
  /* Internet Explorer 11+, Windows Phone 8.1+ */
}

/* # =================================================================
	 # Accessibility
	 # ================================================================= */
/* Hide content from screens but not screenreaders */
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}

/* Specify the progress cursor of updating elements */
[aria-busy="true"] {
  cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled] {
  cursor: default;
}

/* # =================================================================
	 # Selection
	 # ================================================================= */
/* Specify text selection background color and omit drop shadow */
::-moz-selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* Required when declaring ::selection */
  color: #000;
  text-shadow: none;
}

/* # =================================================================
	 # add
	 # ================================================================= */
a {
  transition: all .3s;
}

figure {
  margin: 0;
  padding: 0;
}

figure img {
  max-width: 100%;
}

figure.w100p img {
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

/* # =================================================================
	 # disp
	 # ================================================================= */
.sp-disp {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-disp {
    display: block;
  }
  .pc-disp {
    display: none;
  }
}

/* font setting */
.f_roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

/*
# =================================================================
# COMMON
# =================================================================
*/
/* background -----------------*/
.bg_green {
  background-color: #f6fffa;
}

.bg_gray {
  background-color: #f2f2f2;
}

/* flex -----------------*/
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex.jc-sa {
  justify-content: space-around;
}

.flex.jc-fs {
  justify-content: flex-start;
}

.flex.jc-fe {
  justify-content: flex-end;
}

.flex.ai-c {
  align-items: center;
}

@media screen and (max-width: 768px) {
  .flex.sp_fxd-c {
    flex-direction: column;
  }
}

/* bread -----------------*/
.bread {
  display: none;
  position: relative;
  width: 100%;
  padding: 20px 0;
  background: #3a3939;
  z-index: 0;
}

@media screen and (min-width: 960px) {
  .bread {
    display: block;
  }
}

.bread .bread_crumb {
  width: 96%;
  max-width: 1280px;
  margin: auto;
  text-align: left;
}

.bread .bread_crumb li {
  display: inline;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.4rem;
}

.bread .bread_crumb li::after {
  content: '>';
  padding: 0 15px;
}

.bread .bread_crumb li:last-child::after {
  content: '';
}

.bread .bread_crumb li a {
  color: #fff;
  font-size: 1.4rem;
}

/* margin -----------------*/
/* margin top */
.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

/* margin bottom */
.mb0 {
  margin-bottom: 0px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

/* defTable01 */
.contact_submitBtn {
  text-align: center;
}

.contact_submitBtn input {
  width: 200px;
  margin-top: 30px;
  padding: 10px 0;
  color: #fff;
  font-weight: bold;
  background-color: #083071;
  border: 0;
  font-size: 18px;
  cursor: pointer;
  transition: all .3s;
}

.contact_submitBtn input:hover {
  opacity: .7;
}

.tbl01 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #71bce6;
  font-size: 1.4rem;
  text-align: left;
}

.tbl01 thead th {
  padding: 2rem;
  background-color: #71bce6;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}

.tbl01 thead th small {
  font-size: 1.4rem;
}

.tbl01 tr {
  border-bottom: 1px solid #71bce6;
}

.tbl01 th {
  max-width: 300px;
  padding: 2rem 1rem;
  border: 1px solid #71bce6;
  background-color: #b8e0f7;
  font-weight: normal;
}

.tbl01 td {
  padding: 2rem 1rem;
  border: 1px solid #71bce6;
  background-color: #fff;
}

.tbl01 td:first-child {
  white-space: nowrap;
}

.tbl02 {
  width: 98%;
  margin: 0 auto;
  table-layout: auto;
  background: #fff;
}

.tbl02 tr:nth-child(even) {
  background: #e1ecfd;
}

.tbl02 th {
  font-size: 15px;
  position: relative;
  text-align: left;
  width: 25%;
  padding: 18px 30px;
  vertical-align: middle;
  text-justify: inter-ideograph;
  font-weight: bold;
}

.tbl02 th:after {
  display: block;
  content: "";
  width: 120px;
  height: 2px;
  background-color: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: -120px;
}

.tbl02 td {
  width: 70%;
  padding: 18px 20px 18px 170px;
}

@media screen and (max-width: 768px) {
  .tbl02 {
    width: 100%;
    margin: 0 auto;
    table-layout: auto;
  }
  .tbl02 tr:nth-child(even) {
    background: none;
  }
  .tbl02 th {
    background: #e1ecfd;
    font-size: 14px;
    position: inherit;
    display: block;
    text-align: left;
    width: 100%;
    padding: 8px 10px;
    vertical-align: middle;
    -moz-text-align-last: inherit;
    text-align-last: inherit;
    text-justify: inherit;
    font-weight: bold;
  }
  .tbl02 th::before {
    content: "ー";
    margin-right: 5px;
  }
  .tbl02 th:after {
    display: none;
    content: "";
    width: 30px;
    height: 2px;
    background-color: #333;
    position: inherit;
    top: calc(50% - 1px);
    right: 0px;
  }
  .tbl02 td {
    width: 100%;
    padding: 10px 10px 10px 10px;
    display: block;
  }
}

.list_company {
  width: 85%;
  margin: 30px auto;
  table-layout: auto;
}

.list_company li {
  width: 33.33%;
  list-style: disc;
  list-style-position: inside;
  float: left;
  padding: 10px 10px 10px 30px;
}

.list_company li:nth-child(2n) {
  background: #eee;
}

.list_company li:last-child {
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  .tbl-resp th, .tbl-resp td {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .tbl-scrollWrap {
    width: 100%;
    overflow-y: scroll;
  }
  .tbl-scrollWrap > table {
    width: 1200px;
  }
  .tbl-scrollWrap::-webkit-scrollbar {
    height: 5px;
  }
  .tbl-scrollWrap::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
  }
  .tbl-scrollWrap::-webkit-scrollbar-track-piece {
    background: #efefef;
  }
}

.form {
  width: 100%;
  border: 1px solid #083071;
}

.form th {
  text-align: left;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .form th {
    width: 100%;
  }
}

.form input[type="text"],
.form input[type="email"] {
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border: 1px solid #ddd;
  background: #fff;
}

.form input[type="text"].w60p,
.form input[type="email"].w60p {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .form input[type="text"].w60p,
  .form input[type="email"].w60p {
    width: 100%;
  }
}

.form textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  background: #fff;
}

.form .separate input[type="text"] {
  width: calc(100% / 3.1);
}

.form input[type="button"] {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  background-color: #e6e6e6;
  background-repeat: repeat-x;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(64, 64, 64, 0.1);
}

.form select {
  border: 1px solid #dadada;
  font-family: inherit;
  padding: 5px 10px;
  margin: 0;
  background-color: #fff;
  border-radius: 0;
}

.form_btn {
  margin: 20px auto 0;
}

.con_cen {
  border: 1px solid #ddd;
  text-align: center;
  padding: 40px 50px;
  font-size: 17px;
  line-height: 180%;
  margin: 50px 0px 60px;
}

.con_cen span {
  display: block;
  margin: 1rem 0;
}

.con_cen em {
  font-style: normal;
}

.con_cen .tel_link,
.con_cen .tel_link a {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 4rem;
}

@media screen and (max-width: 768px) {
  .con_cen .tel_link,
  .con_cen .tel_link a {
    font-size: 3.5rem;
  }
}

.required {
  color: #fe6262;
}

ul.contact_formList {
  padding: 0;
  margin: 0;
}

ul.contact_formList li {
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px dashed #ccc;
}

ul.contact_formList li:last-child {
  background: none;
  border-bottom: 0;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.inpform_fl {
  display: inline-block;
  margin: 0 10px 5px 0;
  font-size: 14px;
}

/* split -----------------*/
.col_2 > * {
  width: 48%;
}

@media screen and (max-width: 768px) {
  .col_2 > * {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .col_2 figure {
    order: 1;
  }
  .col_2 .txt {
    order: 2;
  }
}

.col_3 > * {
  width: calc(100% / 3.1);
}

@media screen and (max-width: 768px) {
  .col_3 > * {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_4 > * {
  width: calc(100% / 4.1);
}

@media screen and (max-width: 768px) {
  .col_4 > * {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_5 > * {
  width: calc(100% / 5.1);
}

@media screen and (max-width: 768px) {
  .col_5 > * {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_2_1 > *:nth-of-type(1) {
  width: 68%;
}

@media screen and (max-width: 768px) {
  .col_2_1 > *:nth-of-type(1) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_2_1 > *:nth-of-type(2) {
  width: 31%;
}

@media screen and (max-width: 768px) {
  .col_2_1 > *:nth-of-type(2) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_2_1 figure {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .col_2_1 .txt {
    order: 2;
    margin-top: 2rem;
  }
  .col_2_1 .photo {
    order: 1;
  }
}

.col_2_1 > *:nth-of-type(1) {
  width: 68%;
}

@media screen and (max-width: 768px) {
  .col_2_1 > *:nth-of-type(1) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_2_1 > *:nth-of-type(2) {
  width: 31%;
}

@media screen and (max-width: 768px) {
  .col_2_1 > *:nth-of-type(2) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_1_2 > *:nth-of-type(1) {
  width: 31%;
}

@media screen and (max-width: 768px) {
  .col_1_2 > *:nth-of-type(1) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.col_1_2 > *:nth-of-type(2) {
  width: 68%;
}

@media screen and (max-width: 768px) {
  .col_1_2 > *:nth-of-type(2) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* pagenavi -----------------*/
.wp-pagenavi {
  margin-top: 50px;
  text-align: center;
}

.wp-pagenavi a, .wp-pagenavi span {
  color: #333;
  background-color: #FFFFFF;
  padding: 8px 15px;
  margin: 0 2px;
  white-space: nowrap;
  transition: .2s ease-in-out;
  text-align: center;
  text-decoration: none;
  border: thin solid #083071;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
  opacity: 1;
  color: #fff;
  background-color: #083071;
}

@media screen and (max-width: 760px) {
  .wp-pagenavi {
    margin: 5vw 0;
  }
  .wp-pagenavi a, .wp-pagenavi span {
    padding: 8px 2vw;
  }
}

.page_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

@media screen and (max-width: 960px) {
  .page_nav {
    margin-bottom: 3vw;
  }
}

.page_nav a {
  color: #083071;
  background-color: #FFFFFF;
  padding: 8px 15px;
  margin: 0 2px;
  white-space: nowrap;
  transition: .2s ease-in-out;
  text-align: center;
  text-decoration: none;
  border: thin solid #083071;
}

.page_nav a:hover {
  opacity: 1;
  color: #fff;
  background-color: #083071;
}

/* gmap -----------------*/
.gmap {
  margin-top: 4rem;
  padding: 0;
}

.gmap iframe {
  width: 100%;
  height: 400px;
  border: 2px solid #565656;
  box-sizing: border-box;
}

/* privacy box -----------------*/
.privacyBox {
  padding: 0px 20px 30px;
  width: calc(100% - 0px);
  height: 300px;
  overflow-y: scroll;
  margin-bottom: 20px;
  background: #fff;
  font-size: 90%;
  border: 1px solid #ddd;
}

.privacyBox .sub_ttl {
  margin: 30px 0px 15px 0px;
  font-size: 120%;
  padding: 2px 0px 2px 8px;
  border-left: 5px solid #36a26c;
}

.privacyBox p {
  margin-bottom: 10px;
}

.privacyBox ul.ulbox li {
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 10px;
}

.privacyBox::-webkit-scrollbar {
  height: 5px;
}

.privacyBox::-webkit-scrollbar-track {
  background: #ddd;
}

.privacyBox::-webkit-scrollbar-thumb {
  background: #36a26c;
}

#privacy a {
  color: #083071;
  text-decoration: underline;
}

/* ttl -----------------*/
.ttl-border_twocolor {
  position: relative;
  margin-bottom: 1.5rem;
  border-bottom: solid 3px #083071;
  text-align: left;
  font-size: 2.6rem;
  line-height: normal;
}

.ttl-border_twocolor:after {
  position: absolute;
  content: " ";
  display: block;
  border-bottom: solid 3px #e96c04;
  bottom: -3px;
  width: 20%;
}

@media screen and (max-width: 768px) {
  .ttl-border_twocolor {
    font-size: 2rem;
  }
}

.ttl-center {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 1em;
  font-size: 2.6rem;
  line-height: normal;
  text-align: center;
  color: #083071;
}

.ttl-center:before {
  content: '';
  position: absolute;
  bottom: -15px;
  display: inline-block;
  width: 60px;
  height: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #083071;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .ttl-center {
    font-size: 2rem;
  }
}

/* list -----------------*/
.list_asterisk {
  text-align: left;
}

.list_asterisk li {
  padding-left: 1.3em;
  text-indent: -1.3em;
}

.list_asterisk li:before {
  content: '※';
  margin-right: 5px;
}

/* other -----------------*/
/* Alignment setting */
.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.bg01 {
  background-color: #f6f9ee;
}

.c_red {
  color: #fe6262;
}

.c_orange {
  color: #e96c04;
}

/*
# =================================================================
# LAYOUT
# =================================================================
*/
/*
# =================================================================
# PAGES
# =================================================================
*/
/* Overall top -----------------*/
.lowermainbox-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  background: #083071;
  background-size: cover;
  background: url(../images/top/bg_about.jpg) no-repeat center 74%;
  background-size: cover;
  overflow: hidden;
}

.lowermainbox-wrap h2 {
  font-size: 4rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  line-height: 1.5;
}

.lowermainbox-wrap h2 span {
  display: block;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .lowermainbox-wrap h2 {
    font-size: 2.5rem;
  }
}

.subcontents-inner {
  max-width: 1040px;
  padding: 5rem 0;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .subcontents-inner {
    width: 96%;
  }
}

.page-service .intro {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page-service .intro {
    text-align: left;
  }
}

.page-service .strength {
  margin-top: 8rem;
}

.page-service .strength .flex + .ttl-border_twocolor {
  margin-top: 6rem;
}

.page-service .strength .btn01 {
  margin-top: 2rem;
}

.page-service .area .tbl02 {
  margin-top: 2rem;
}

.page-service .area .txt {
  margin-top: 2rem;
  padding: 3rem;
  background: #fff;
  border: double 4px #083071;
}

.page-service .area .txt h4 {
  margin-bottom: 1.5rem;
  color: #e96c04;
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

.page-service .area .txt small {
  display: block;
  margin-top: 1rem;
}

.page-work.archive .flex {
  justify-content: space-between;
  margin-bottom: 2rem;
}

.page-work.archive .item {
  position: relative;
  width: calc(100% / 2.1);
  padding: 2rem;
  border: 4px solid #083071;
}

@media screen and (max-width: 768px) {
  .page-work.archive .item {
    width: 100%;
  }
  .page-work.archive .item:nth-of-type(n+2) {
    margin-top: 4rem;
  }
}

.page-work.archive .item:nth-of-type(n+6) {
  margin-top: 1.5%;
}

.page-work.archive .item:nth-of-type(n+3) {
  margin-top: 4rem;
}

.page-work.archive .item figure {
  margin: 0 61px;
  overflow: hidden;
  width: 300px;
  height: 200px;
  position: relative;
}

.page-work.archive .item figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .page-work.archive .item figure {
    margin: 0 auto;
  }
  .page-work.archive .item figure + figure {
    margin-top: 5rem;
  }
}

.page-work.archive .item .ttl {
  padding-bottom: 5px;
  margin-bottom: 2rem;
  color: #083071;
  font-weight: bold;
  border-bottom: 1px solid #083071;
  font-size: 20px;
  font-size: 2rem;
}

.blog.detail .date {
  display: block;
  margin-bottom: 3rem;
  color: #666;
  text-align: right;
}

.blog.detail .ttl-border_twocolor {
  margin-top: 0;
  font-size: 2.5rem;
}

.blog.detail .editor-block {
  margin-top: 2rem;
  padding: 3rem;
  background: #fff;
}

.blog.detail .editor-block p {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 1em;
}

.blog.detail .editor-block .aligncenter {
  display: block;
  margin: 5px auto 20px auto;
}

.blog.detail .editor-block .alignright {
  float: right;
  margin: 5px 0 20px 5%;
}

.blog.detail .editor-block .alignleft {
  float: left;
  margin: 5px 5% 20px 0;
}

@media screen and (max-width: 768px) {
  .blog.detail .editor-block p {
    font-size: 13px;
    font-size: 1.3rem;
    margin-bottom: .5em;
  }
  .blog.detail .editor-block img {
    width: 100%;
  }
  .blog.detail .editor-block .alignright {
    float: none;
    margin: 1em 0;
  }
  .blog.detail .editor-block .alignleft {
    float: none;
    margin: 1em 0;
  }
}

.page-company .intro {
  padding: 9rem 1rem;
}

@media screen and (max-width: 768px) {
  .page-company .intro {
    padding: 3rem .5rem;
  }
}

.page-company .intro .txt p {
  line-height: 2;
}

.page-company .qualification .flex {
  justify-content: space-between;
  align-items: flex-start;
}

.page-company .qualification .flex div {
  display: block;
  width: calc(100% / 2.2);
}

.page-company .qualification .flex div p {
  margin-bottom: 1rem;
  text-align: center;
}

.page-diagnosis .block {
  margin-top: 6rem;
}

.page-diagnosis .block ul {
  border: double 4px #083071;
  padding: 0.5em 1em 0.5em 2.3em;
  position: relative;
}

.page-diagnosis .block ul li {
  line-height: 1.5;
  padding: 0.5em 0;
  list-style-type: none !important;
  font-size: 1.5rem;
}

.page-diagnosis .block ul li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0a4";
  position: absolute;
  left: .8em;
  color: #083071;
}

.page-diagnosis .block .txt {
  margin-top: 2rem;
  padding: 3rem;
  background: #eee;
}

.page-diagnosis .block .txt h4 {
  margin-bottom: 1.5rem;
  color: #e96c04;
  text-align: center;
  font-size: 2.2rem;
  font-weight: bold;
}

.page-diagnosis .block .txt .btn01 {
  width: 300px;
  margin-top: 1.5rem;
}

.page-privacy .intro {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page-privacy .intro {
    text-align: left;
  }
}

.page-privacy .intro p {
  line-height: 2;
}

.page-privacy .ulbox {
  padding: 0px 0px 0px 0px;
  font-size: 14px;
  margin: 0px 0px 5px 5px;
}

.page-privacy .ulbox li {
  padding: 5px 0px;
  list-style-type: disc;
  overflow: visible;
  list-style-position: inside;
}

.page-privacy hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 4rem auto;
}

.page-privacy .bg_green {
  padding: 0 0 5rem;
}

.page-sitemap {
  padding: 0 0 5rem;
}

.page-sitemap li {
  width: calc(100% /2.2);
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .page-sitemap li {
    width: 100%;
  }
}

.page-sitemap a {
  position: relative;
  display: block;
  padding: 1.5rem 1rem;
  background: #fff;
  border: 1px solid #ddd;
  color: #313131;
  font-size: 1.7rem;
  text-align: center;
  transition: all .3s;
}

.page-sitemap a:hover {
  background: #083071;
  color: #fff;
}

.page-sitemap a:hover:before {
  left: 0;
  width: 30%;
  height: 2px;
  background: #e96c04;
}

.page-contact table + .btn01 {
  margin-top: 5rem;
  color: #fff;
}
