@mixin flex {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&.jc-sa{
		justify-content: space-around;
	}

	&.jc-fs{
		justify-content: flex-start;
	}

	&.jc-fe{
		justify-content: flex-end;
	}

	&.ai-c{
		align-items: center;
	}

	&.sp_fxd-c{
		@include mq-down(){
			flex-direction: column;
		}
	}
}

@mixin fz($size: 14) {
	font-size: $size + px;
	font-size: ($size / 10)  + rem;
}

@mixin middleline($height, $ie: 0){
	height: $height + px;
	line-height: $height + px;
	&:not(:target){
		line-height: $height + $ie + px + \9;
	}
	:root & {
		line-height: $height + $ie + px + \9;
	}
}

@mixin transition {
	-webkit-transition: all .4s;
	transition: all .4s;
}

@mixin hover {
	-webkit-transition: all .4s;
	transition: all .4s;

	&:hover{
		opacity:.7;
	}
}

@mixin arw_image {
	background-repeat: no-repeat;
	background-position: 90% center;
}

@mixin img-scale {
	-moz-transition: -moz-transform 0.4s linear;
	-webkit-transition: -webkit-transform 0.4s linear;
	-o-transition: -o-transform 0.4s linear;
	-ms-transition: -ms-transform 0.4s linear;
	transition: transform 0.4s linear;
}
@mixin img-scale_hover {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 0.8;
}

$breakpoints: (
	'sm': 'screen and (min-width: 768px)',
	'md': 'screen and (min-width: 992px)',
	'lg': 'screen and (min-width: 1080px)',
	'xl': 'screen and (min-width: 1280px)',
	) !default;

@mixin mq($breakpoint: sm) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

// max-width
$breakpoint-down: (
	'sm': 'screen and (max-width: 399px)',
	'md': 'screen and (max-width: 768px)',
	'lg': 'screen and (max-width: 999px)',
	'xl': 'screen and (max-width: 1199px)',
	) !default;

@mixin mq-down($breakpoint: md) {
	@media #{map-get($breakpoint-down, $breakpoint)} {
		@content;
	}
}

@mixin responsive($class, $bp: $breakpoint-up) {
	#{$class} {
		@content;
	}
	@each $suffix, $value in $bp {
		@media #{$value} {
			#{$class}-#{$suffix} {
				@content;
			}
		}
	}
}




// ===================================================================
// mixinやらCSS3やら、extendも。
// ===================================================================


@mixin font-size($size: 14 ,$is_important: false) {
	font-size: $size + px if($is_important , !important, null);
	// font-size: ($size / 10)  + rem if($is_important , !important, null);
}

// 横 320px基準のpxフォントサイズを入れると、一緒にvwのフォントサイズを出力
// スマホのデザインデータがw640px以外の場合は、適宜変更
@mixin px_vw($name, $size: 14 ,$is_important: false) {
	$rate: 100 / 375;
	#{$name}: $size + px if($is_important , !important, null);
	#{$name}: $rate * $size * 1vw if($is_important , !important, null);
}


@mixin retina {
	@media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 2dppx) {
		@content;
	}
}

// Media Queries
@mixin under_minipc {
	// PC表示: 768+20=788px～
	@if $is_responsive == true {
		@media screen and (max-width: $width_mini_pc) {
			@content;
		}
	}
}
@mixin under_tablet {
	// タブレット表示: 480+20=500px ～ 768+20-1=787px
	@if $is_responsive == true {
		@media screen and (max-width: $width_tablet) {
			@content;
		}
	}
}
@mixin under_sp {
	// スマートフォン表示: 480+20-1=499px～
	@if $is_responsive == true {
		@media screen and (max-width: $width_sp) {
			@content;
		}
	}
}
@mixin under_width($width) {
	@if $is_responsive == true {
		@media screen and (max-width: $width) {
			@content;
		}
	}
}


@mixin over_width($width) {
	@if $is_responsive == true {
		@media screen and (min-width: $width) {
			@content;
		}
	}
}


// 値にプレフィックスを付与
@mixin minus_width($value) {
	width: -webkit-calc(100% - #{$value});
	width: -moz-calc(100% - #{$value});
	width: calc(100% - #{$value});
}

// 値にプレフィックスを付与
@mixin set_column($col: 2, $m: 0, $cell: '*') {
	@include legacy-pie-clearfix;
	& > #{$cell} {
		width: if($col == 1, auto !important, (100% - $m * ($col - 1)) / $col);
		float: if($col == 1, none !important, left);
		clear: if($col == 1, both, none);
		margin-right: $m;
		// margin-bottom: $mb;
	}
	@for $i from 1 through $col - 1 {
		& > #{$cell}:nth-child(#{$col}n-#{$i}){
			float: if($col == 1, none !important, left);
			clear: if($col == 1, both, none);
			margin-right: $m;
			// margin-bottom: $mb;
		}
	}
	@if $col != 1 {
		& > #{$cell}:nth-child(#{$col}n){ float: right; margin-right: 0; clear: none;}
		& > #{$cell}:nth-child(#{$col}n-1){ margin-right: 0; clear: none;}
		& > #{$cell}:nth-child(#{$col}n-#{$col - 1}){ clear: both;}
	}
}


// 文字を省略して末尾を三点リーダー
@mixin line-clamp($clamp: 3) {
	-webkit-line-clamp: $clamp;
					line-clamp: $clamp;
	-webkit-box-orient: vertical;
					box-orient: vertical;
	display: -webkit-box;
	display: box;
	overflow: hidden;
	white-space: normal;
	text-overflow: ellipsis;
}

// gradient
@mixin gradient($start-color: #CCC, $end-color: #000, $direction: v) {
	$linear: top;
	$filter: vertical;
	@if $direction == h {
		$linear: left;
		$filter: horizontal;
	}
	background-color: $end-color;
	@include background-image(linear-gradient($linear, $start-color, $end-color));
	@include filter-gradient($start-color, $end-color, $filter);
}

// 三角
@mixin triangle($color: #000, $direction: top, $height: 10px, $width: 10px, $position: null, $bd_color: null) {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		z-index: 2;
		border-style: solid;
		@if $direction == "top" {
			top: -$height;
			left: 0;
			border-width: 0 $width $height $width;
			border-color: transparent transparent $color transparent;
		}
		@else if $direction == "right" {
			top: 0;
			right: -$height;
			border-width: $width 0 $width $height;
			border-color: transparent transparent transparent $color;
		}
		@else if $direction == "bottom" {
			bottom: -$height;
			left: 0;
			border-width: $height $width 0 $width;
			border-color: $color transparent transparent transparent;
		}
		@else if $direction == "left" {
			top: 0;
			left: -$height;
			border-width: $width $height $width 0;
			border-color: transparent #{$color} transparent transparent;
		}
		@if length($direction) == 2 {
			$top-or-bottom: nth($direction, 1);
			$left-or-right: nth($direction, 2);
			@if $top-or-bottom == "top" {
				@if $left-or-right == "left" {
					border-width: $height $height 0 0;
					border-color: $color transparent transparent transparent;
				}
				@else if $left-or-right == "right" {
					border-width: 0 $height $height 0;
					border-color: transparent $color transparent transparent;
				}
			}
			@else if $top-or-bottom == "bottom" {
				@if $left-or-right == "left" {
					border-width: $height 0 0 $height;
					border-color: transparent transparent transparent $color;
				}
				@else if $left-or-right == "right" {
					border-width: 0 0 $height $height;
					border-color: transparent transparent $color transparent;
				}
			}
		}
		@if $position == "center" {
			left: 50%;
			margin-left: - $width;
		}
		@else if $position == "middle" {
			top: 50%;
			margin-top: - $height;
		}
		@else if $position AND ($direction == "left" OR $direction == "right"){
			top: $position;
		}
		@else if $position AND ($direction == "top" OR $direction == "bottom"){
			left: $position;
		}
	}
	@if $bd_color {
		&:after {
			$bd_height: $height + 1px;
			content: "";
			position: absolute;
			z-index: 1;
			border-style: solid;
			@if $direction == "top" {
				top: -$bd_height;
				left: 0;
				border-width: 0 $width $height $width;
				border-color: transparent transparent $bd_color transparent;
			}
			@else if $direction == "right" {
				top: 0;
				right: -$bd_height;
				border-width: $width 0 $width $height;
				border-color: transparent transparent transparent $bd_color;
			}
			@else if $direction == "bottom" {
				bottom: -$bd_height;
				left: 0;
				border-width: $height $width 0 $width;
				border-color: $bd_color transparent transparent transparent;
			}
			@else if $direction == "left" {
				top: 0;
				left: -$bd_height;
				border-width: $width $height $width 0;
				border-color: transparent #{$bd_color} transparent transparent;
			}
			@if length($direction) == 2 {
				$top-or-bottom: nth($direction, 1);
				$left-or-right: nth($direction, 2);
				@if $top-or-bottom == "top" {
					@if $left-or-right == "left" {
						border-width: $height $height 0 0;
						border-color: $bd_color transparent transparent transparent;
					}
					@else if $left-or-right == "right" {
						border-width: 0 $height $height 0;
						border-color: transparent $bd_color transparent transparent;
					}
				}
				@else if $top-or-bottom == "bottom" {
					@if $left-or-right == "left" {
						border-width: $height 0 0 $height;
						border-color: transparent transparent transparent $bd_color;
					}
					@else if $left-or-right == "right" {
						border-width: 0 0 $height $height;
						border-color: transparent transparent $bd_color transparent;
					}
				}
			}
			@if $position == "center" {
				left: 50%;
				margin-left: - $width;
			}
			@else if $position == "middle" {
				top: 50%;
				margin-top: - $height;
			}
			@else if $position AND ($direction == "left" OR $direction == "right"){
				top: $position;
			}
			@else if $position AND ($direction == "top" OR $direction == "bottom"){
				left: $position;
			}
		}
	}
}

// 格子状の背景
@mixin bg-square($color: #000, $width: 10px) {
	@include background-image(
		linear-gradient(top, color-stops($color, $color 1px, transparent 1px, transparent 100%)),
		linear-gradient(left, color-stops($color, $color 1px, transparent 1px, transparent 100%))
		);
	background-size: $width $width;
}

// 水玉の背景
// background: #c3e9fa;
// @include bg-dotted(#b2e2f7, 25px, 20%);
@mixin bg-dotted($color: #000, $width: 10px, $size: 20%, $is_tilt: true) {
	background-image: radial-gradient($color $size, transparent 0), radial-gradient($color $size, transparent 0);
	@if $is_tilt == true {
		background-position: 0 0, ($width / 2) ($width / 2);
	}
	background-size: $width $width;
}

//斜線 左下～右上 ストライプ
//h(bg),s(bg),l(bg),h(gra),s(gra),l(gra),ichi(線の太さ10～20%で指定),bg-size
@mixin bg-stripe($color: #CCC ,$deg: -45deg, $weight: 10%, $size: 4px, $bgcolor: #FFF){
	@include background-image(
		linear-gradient(
			-45deg,
			$color 0%,
			$color $weight,
			$bgcolor ($weight + 1%),
			$bgcolor ((50% - $weight) - 1%),
			$color (50% - $weight),
			$color (50% + $weight),
			$bgcolor ((50% + $weight) + 1%),
			$bgcolor ((100% - $weight) - 1%),
			$color (100% - $weight),
			$color 100%
			));
	background-repeat:repeat;
	background-position:left top;
	@include background-size( $size $size);
}

// 背景画像と同じサイズのブロックを作成
@mixin bg-img-block($img, $is_2x: false) {
	@include inline-block;
	@if $is_2x == true {
		width: image-width($img) /2;
		height: image-height($img) /2;
	}
	@else {
		width: image-width($img);
		height: image-height($img);
	}
	background: image-url($img);
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

// 1.618
// 2.414
@mixin ratio_box($rate: 1) {
	display: block;
	width: auto;
	&:after {
		content: " ";
		display: block;
		width: 100%;
		padding-top: (100 / $rate) * 1%;
	}
}



@mixin bg-gradient($angle: 180deg, $color: #0bd, $amount: 20%) {
	background: linear-gradient($angle, $color, adjust-hue($color, $amount)) fixed;
}