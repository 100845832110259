.page-diagnosis {


	.block {
		margin-top: 6rem;



		ul {
			border: double 4px $blue;
			padding: 0.5em 1em 0.5em 2.3em;
			position: relative;

			li {
				line-height: 1.5;
				padding: 0.5em 0;
				list-style-type: none !important;
				font-size: 1.5rem;

				&:before {
					font-family: "Font Awesome 5 Free";
					content: "\f0a4";
					position: absolute;
					left: .8em;
					color: $blue;
				}
			}
		}


		.txt {
			margin-top: 2rem;
			padding: 3rem;
			background: #eee;

			h4 {
				margin-bottom: 1.5rem;
				color: $orange;
				text-align: center;
				font-size: 2.2rem;
				font-weight: bold;
			}

			.btn01 {
				width: 300px;
				margin-top: 1.5rem;
			}
		}
	}


}