.gmap {
  margin-top: 4rem;
  padding: 0;

  iframe {
    width: 100%;
    height: 400px;
    border: 2px solid $gray;
    box-sizing:border-box;
  }
}