
/* defTable01 */

// table.defTable01 {
// 	display: inline-table;
// 	width: 100%;
// 	margin-top: 40px;
// 	background:#fff;
// 	tr{
// 		&:nth-of-type(odd){
// 			background-color:lighten($blue,65%);
// 		}
// 	}
// 	th {
// 		padding: 10px 0;
// 		border-right: 1px dotted #ddd;
// 		width: 250px;
// 		font-weight: bold;
// 	}
// 	td {
// 		padding: 25px 10px 25px 50px;
// 		text-align: left;
// 		&.cen {
// 			text-align: center;
// 		}
// 	}

// 	&.white{
// 		background:#fff;
// 	}

// 	&.form{
// 		th{
// 			vertical-align:middle;
// 		}

// 		th,td{
// 			padding: 3vw;
// 		}

// 		input[type="radio"]{
// 			margin-top: 0;
// 			margin-right: 0;
// 		}

// 		select{
// 			border-color: #aaa;
// 		}

// 		input[type="text"]{
// 			width: 60%;
// 			border-color: #aaa;
// 			&:focus{
// 				outline:1px solid $blue;
// 			}

// 			&.w30p{
// 				width: 30%;
// 			}
// 			@media screen and (max-width: 768px) {
// 				width: 100%;
// 			}
// 		}

// 		.address{
// 			span{
// 				display: block;
// 			}
// 		}

// 		#searchBtn{
// 			background-color:$blue;
// 			color:#fff;
// 			cursor: pointer;
// 			transition:all .3s;

// 			&:hover{
// 				opacity:.7;
// 			}
// 		}

// 		textarea{
// 			border-color: #aaa;
// 			height: 150px;
// 			&:focus{
// 				outline:1px solid $blue;
// 			}
// 		}

// 		label{
// 			@media screen and (max-width: 768px) {
// 				display: block;
// 				margin-bottom: 10px;
// 			}
// 		}
// 	}
// }

// .agrBox {
// 	text-align: center;
// }

// table.defTable01 {
// 	border:1px solid $blue;
// 	.clrred {
// 		color: #FF0000;
// 		font-style: normal;
// 		margin-left: 5px;
// 		padding: 2px 5px;
// 		font-size: 12px;
// 	}
// 	td.cen input {
// 		padding: 10px;
// 		color: #91c14d;
// 		font-size: 16px;
// 		width: 230px;
// 		border: 2px solid #91c14d;
// 		cursor: pointer;
// 		font-weight: bold;
// 		&:hover {
// 			background: #429208;
// 			color: #fff;
// 			border: 2px solid #429208;
// 			opacity: 0.8;
// 			filter: alpha(opacity = 80);
// 			transition: 0.3s ease-in-out;
// 		}
// 	}
// 	tr.bbn {
// 		border-bottom: none !important;
// 		th, td {
// 			border-bottom: none !important;
// 		}
// 	}
// 	a.bb01 {
// 		color: #efe384;
// 	}
// 	.privacyBox {
// 		padding: 0px 20px 30px;
// 		width: calc(100% - 0px);
// 		height: 300px;
// 		overflow-y: scroll;
// 		margin-bottom: 20px;
// 		background: #fff;
// 		font-size: 90%;
// 		border: 1px solid #ddd;
// 		.sub_ttl {
// 			margin: 30px 0px 15px 0px;
// 			font-size: 120%;
// 			padding: 2px 0px 2px 8px;
// 			border-left: 5px solid $blue;
// 		}
// 		p {
// 			margin-bottom: 10px;
// 		}
// 		ul.ulbox li {
// 			list-style-type: disc;
// 			list-style-position: inside;
// 			margin-left: 10px;
// 		}
// 		&::-webkit-scrollbar {
// 			height: 5px;
// 		}
// 		&::-webkit-scrollbar-track {
// 			background: #ddd;
// 		}
// 		&::-webkit-scrollbar-thumb {
// 			background: $blue;
// 		}
// 	}
// 	input,select {
// 		padding: 10px 10px;
// 		background: #fff;
// 		font-size: 14px;
// 		border: 1px solid #ddd;
// 	}
// 	textarea {
// 		padding: 10px 10px;
// 		width: calc(100% - 20px);
// 		background: #fff;
// 		font-size: 13px;
// 		border: 1px solid #ddd;
// 	}
// 	td.tel_area input {
// 		width: 10%;
// 		min-width: auto;
// 		ime-mode: disabled;
// 	}
// 	input[type="checkbox"] {
// 		min-width: auto;
// 	}
// 	span.wpcf7-list-item {
// 		margin: 0 0 0 30px;
// 		height: 20px;
// 		line-height: 20px;
// 		&.first {
// 			margin: 0 0 0 0;
// 		}
// 		input {
// 			vertical-align: middle;
// 			margin: 0 0 2px 0;
// 			min-width: auto;
// 		}
// 	}
// }

// @media screen and (max-width: 768px) {
// 	p.txt_contact {
// 		padding: 20px 20px;
// 		text-align: center;
// 		line-height: 180%;
// 		font-size: 13px;
// 	}

// 	table.defTable01 {
// 		display: inline-table;
// 		box-sizing: border-box;
// 		// border-collapse:separate;
// 		tr{
// 			border-bottom: 1px solid #aaa;
// 		}
// 		th {
// 			padding: 8px 10px 8px 10px;
// 			// border-bottom: 1px dashed #aaa;
// 			// border-top: 1px solid #d8ebef;
// 			width: 100%;
// 			display: block;
// 			border-right: 0;
// 			text-align: left;
// 			box-sizing: border-box;
// 		}
// 		td {
// 			padding: 10px;
// 			border-bottom: none;
// 			width: 100%;
// 			display: block;
// 			box-sizing: border-box;
// 			&.cen {
// 				text-align: center;
// 			}
// 		}
// 		&.white{
// 			padding:5vw;
// 		}

// 	}

// 	table.defTable01 {
// 		td.cen input {
// 			width: 100%;
// 		}
// 		a.bb01 {
// 			color: #efe384;
// 		}
// 		td.tel_area input {
// 			width: 30%;
// 		}
// 		input[type="checkbox"] {
// 			width: 4%;
// 		}
// 		.privacyBox {
// 			height: 250px;
// 			p {
// 				font-size: 13px;
// 			}
// 		}
// 		input:not(input[type="radio"]){
// 			width: 100%;
// 		}
// 		textarea {
// 			width: 100%;
// 		}
// 		td.tel_area input {
// 			width: 30%;
// 		}
// 		input[type="checkbox"] {
// 			width: 15px;
// 		}
// 		span.wpcf7-list-item {
// 			display: block;
// 			margin: 0;
// 			&.first {
// 				display: block;
// 				margin: 0;
// 			}
// 			input {
// 				padding: 0px;
// 				margin: 0 5px 2px 0;
// 				width: 17px;
// 				height: 15px;
// 				vertical-align: middle;
// 			}
// 		}
// 	}
// }

.contact_submitBtn{
	text-align: center;
	input{
		width: 200px;
		margin-top: 30px;
		padding: 10px 0;
		color:#fff;
		font-weight: bold;
		background-color:$blue;
		border:0;
		font-size:18px;
		cursor: pointer;
		transition:all .3s;

		&:hover{
			opacity:.7;
		}
	}
}

.tbl01{
	width: 100%;
	border-collapse:collapse;
	border:1px solid #71bce6;
	font-size:1.4rem;
	text-align: left;
	thead{
		th{
			padding:2rem;
			background-color:#71bce6;
			color:#fff;
			font-size:2rem;
			font-weight: bold;

			small{
				font-size: 1.4rem;
			}
		}
	}
	tr{
		border-bottom:1px solid #71bce6;
	}
	th{
		max-width:300px;
		padding:2rem 1rem;
		border:1px solid #71bce6;
		background-color: #b8e0f7;
		font-weight: normal;
	}
	td{
		padding:2rem 1rem;
		border:1px solid #71bce6;
		background-color:#fff;

		&:first-child{
			white-space: nowrap;
		}

	}
}


// #sub_contents {
	.tbl02 {
		width: 98%;
		margin: 0 auto;
		table-layout: auto;
		background:#fff;
		tr:nth-child(even) {
			background: lighten($blue,70%);
		}
		th {
			font-size: 15px;
			position: relative;
			text-align: left;
			width: 25%;
			padding: 18px 30px;
			vertical-align: middle;
			// text-align-last: justify;
			text-justify: inter-ideograph;
			font-weight: bold;
			&:after {
				display: block;
				content: "";
				width: 120px;
				height: 2px;
				background-color: #333;
				position: absolute;
				top: calc(50% - 1px);
				right: -120px;
			}
		}
		td {
			width: 70%;
			padding: 18px 20px 18px 170px;
		}

		@include mq-down(){
			width: 100%;
			margin: 0 auto;
			table-layout: auto;
			tr:nth-child(even) {
			  background: none;
			}
			th {
			  background: lighten($blue,70%);
			  font-size: 14px;
			  position: inherit;
			  display: block;
			  text-align: left;
			  width: 100%;
			  padding: 8px 10px;
			  vertical-align: middle;
			  -moz-text-align-last: inherit;
			  text-align-last: inherit;
			  text-justify: inherit;
			  font-weight: bold;
			  &::before {
			    content: "ー";
			    margin-right: 5px;
			  }
			  &:after {
			    display: none;
			    content: "";
			    width: 30px;
			    height: 2px;
			    background-color: #333;
			    position: inherit;
			    top: calc(50% - 1px);
			    right: 0px;
			  }
			}
			td {
			  width: 100%;
			  padding: 10px 10px 10px 10px;
			  display: block;
			}
		}
	}



	.list_company {
		width: 85%;
		margin: 30px auto;
		table-layout: auto;
		li {
			width: 33.33%;
			list-style: disc;
			list-style-position: inside;
			float: left;
			padding: 10px 10px 10px 30px;
			&:nth-child(2n) {
				background: #eee;
			}
			&:last-child {
				list-style-type: none;
			}
		}
	}
// }

@include mq-down(){
	.tbl-resp{
		th,td{
			display: block;
			width: 100%;
			max-width: 100%;
		}
	}


	.tbl-scrollWrap{
		width: 100%;
		overflow-y:scroll;
		& > table{
			width: 1200px;
		}

		&::-webkit-scrollbar {
			height: 5px;
		}
		&::-webkit-scrollbar-thumb{
			background: #999;
			border-radius: 5px;
		}
		&::-webkit-scrollbar-track-piece {
			background: #efefef;
		}
	}
}

.form{
	width: 100%;
	border: 1px solid $blue;
	th{
		// width: 200px;
		text-align: left;
		font-weight: bold;
		@include mq-down(){
			width:100%;
		}
	}
	input[type="text"],
	input[type="email"]{
		width: 100%;
		height: 35px;
		padding:0 10px;
		border: 1px solid #ddd;
		background: #fff;

		&.w60p{
			width: 60%;
			@include mq-down(){
				width: 100%;
			}
		}
	}
	textarea{
		width: 100%;
		height: 200px;
		padding:10px;
		border: 1px solid #ddd;
		background: #fff;
	}

	.separate{
		input[type="text"]{
			width:calc(100% / 3.1);
		}
	}


	input[type="button"]{
		padding: 5px 10px;
		font-size: 12px;
		font-weight: bold;
		color: #333;
		background-color: #e6e6e6;
		background-repeat: repeat-x;
		// background-image: linear-gradient(top, #f4f4f4, #e6e6e6);
		border: 1px solid #d2d2d2;
		border-radius: 5px;
		box-shadow: 0 1px 2px rgba(64, 64, 64, 0.1);
	}

	select{
			border: 1px solid #dadada;
	font-family: inherit;
	padding: 5px 10px;
	margin: 0;
	background-color: #fff;
	border-radius: 0;
	}

}

.form_btn{
	margin: 20px auto 0;
}

.con_cen {
	border: 1px solid #ddd;
	text-align: center;
	padding: 40px 50px;
	font-size: 17px;
	line-height: 180%;
	margin: 50px 0px 60px;
	span {
		display: block;
		margin:1rem 0;
	}

	em{
		font-style: normal;
	}

	.tel_link,
	.tel_link a{
		font-family: 'Josefin Sans', sans-serif;
		// font-weight: bold;
		font-size: 4rem;

		@include mq-down(){
			font-size: 3.5rem;
		}
	}
}


.required{
	color:#fe6262;
}


ul.contact_formList{
	padding: 0;
	margin: 0;
}


ul.contact_formList li {
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	border-bottom: 1px dashed #ccc;
}

ul.contact_formList li:last-child{
	background:none;
	border-bottom: 0;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
}


.inpform_fl {
		display: inline-block;
		margin: 0 10px 5px 0;
		font-size: 14px;
}

