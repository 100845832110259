/* # =================================================================
	 # Global selectors
	 # ================================================================= */

html {
	box-sizing: border-box;
	overflow-y: scroll;
	/* All browsers without overlaying scrollbars */
	-webkit-text-size-adjust: 100%;
	/* iOS 8+ */
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: 62.5%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: 1.4rem;
	font-family: "Noto Sans Japanese",'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
}

*,
::before,
::after {
	background-repeat: no-repeat;
	/* Set `background-repeat: no-repeat` to all elements and pseudo elements */
	// box-sizing: inherit;
	box-sizing: border-box;
}

::before,
::after {
	text-decoration: inherit;
	/* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
	vertical-align: inherit;
}

* {
	padding: 0;
	/* Reset `padding` and `margin` of all elements */
	margin: 0;
}



/* # =================================================================
	 # General elements
	 # ================================================================= */

/* Add the correct display in iOS 4-7.*/
audio:not([controls]) {
	display: none;
	height: 0;
}

hr {
	overflow: visible;
	/* Show the overflow in Edge and IE */
}

/*
* Correct `block` display not defined for any HTML5 element in IE 8/9
* Correct `block` display not defined for `details` or `summary` in IE 10/11
* and Firefox
* Correct `block` display not defined for `main` in IE 11
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}

summary {
	display: list-item;
	/* Add the correct display in all browsers */
}

small {
	font-size: 80%;
	/* Set font-size to 80% in `small` elements */
}

[hidden],
template {
	display: none;
	/* Add the correct display in IE */
}

abbr[title] {
	border-bottom: 1px dotted;
	/* Add a bordered underline effect in all browsers */
	text-decoration: none;
	/* Remove text decoration in Firefox 40+ */
}

a {
	background-color: transparent;
	/* Remove the gray background on active links in IE 10 */
	-webkit-text-decoration-skip: objects;
	/* Remove gaps in links underline in iOS 8+ and Safari 8+ */
	text-decoration: none;
}

a:active,
a:hover {
	outline-width: 0;
	/* Remove the outline when hovering in all browsers */
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	/* Specify the font family of code elements */
}

b,
strong {
	font-weight: bolder;
	/* Correct style set to `bold` in Edge 12+, Safari 6.2+, and Chrome 18+ */
}

dfn {
	font-style: italic;
	/* Address styling not present in Safari and Chrome */
}

/* Address styling not present in IE 8/9 */
mark {
	background-color: #ff0;
	color: #000;
}

/* https://gist.github.com/unruthless/413930 */
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

h1,h2,h3,h4,h5,h6{
	font-weight: normal;
}

/* # =================================================================
	 # Forms
	 # ================================================================= */

input {
	border-radius: 0;
}

/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
	cursor: pointer;
}

input[type="submit"] {
	-webkit-appearance: none;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
	cursor: default;
}

[type="number"] {
	width: auto;
	/* Firefox 36+ */
}

[type="search"] {
	-webkit-appearance: textfield;
	/* Safari 8+ */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
	/* Safari 8 */
}

textarea {
	overflow: auto;
	/* Internet Explorer 11+ */
	resize: vertical;
	/* Specify textarea resizability */
}

button,
input,
optgroup,
select,
textarea {
	font: inherit;
	/* Specify font inheritance of form elements */
}

optgroup {
	font-weight: bold;
	/* Restore the font weight unset by the previous rule. */
}

button {
	overflow: visible;
	/* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: 0;
	padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	outline: 1px dotted ButtonText;
}

button,
html [type="button"],
/* Prevent a WebKit bug where (2) destroys native `audio` and `video`controls in Android 4 */
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
	/* Correct the inability to style clickable types in iOS */
}

button,
select {
	text-transform: none;
	/* Firefox 40+, Internet Explorer 11- */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
	background-color: transparent;
	border-style: none;
	color: inherit;
}

/* Style select like a standard input */
select {
	-moz-appearance: none;
	/* Firefox 36+ */
	-webkit-appearance: none;
	/* Chrome 41+ */
}

select::-ms-expand {
	display: none;
	/* Internet Explorer 11+ */
}

select::-ms-value {
	color: currentColor;
	/* Internet Explorer 11+ */
}

legend {
	border: 0;
	/* Correct `color` not being inherited in IE 8/9/10/11 */
	color: inherit;
	/* Correct the color inheritance from `fieldset` elements in IE */
	display: table;
	/* Correct the text wrapping in Edge and IE */
	max-width: 100%;
	/* Correct the text wrapping in Edge and IE */
	white-space: normal;
	/* Correct the text wrapping in Edge and IE */
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	/* Correct the inability to style clickable types in iOS and Safari */
	font: inherit;
	/* Change font properties to `inherit` in Chrome and Safari */
}

[type="search"] {
	-webkit-appearance: textfield;
	/* Correct the odd appearance in Chrome and Safari */
	outline-offset: -2px;
	/* Correct the outline style in Safari */
}

/* # =================================================================
	 # Specify media element style
	 # ================================================================= */

img {
	border-style: none;
	/* Remove border when inside `a` element in IE 8/9/10 */
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera */
progress {
	vertical-align: baseline;
}

svg:not(:root) {
	overflow: hidden;
	/* Internet Explorer 11- */
}

audio,
canvas,
progress,
video {
	display: inline-block;
	/* Internet Explorer 11+, Windows Phone 8.1+ */
}

/* # =================================================================
	 # Accessibility
	 # ================================================================= */

/* Hide content from screens but not screenreaders */
@media screen {
	[hidden~="screen"] {
		display: inherit;
	}

	[hidden~="screen"]:not(:active):not(:focus):not(:target) {
		position: absolute !important;
		clip: rect(0 0 0 0) !important;
	}
}

/* Specify the progress cursor of updating elements */
[aria-busy="true"] {
	cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
	cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled] {
	cursor: default;
}

/* # =================================================================
	 # Selection
	 # ================================================================= */

/* Specify text selection background color and omit drop shadow */

::-moz-selection {
	background-color: #b3d4fc;
	/* Required when declaring ::selection */
	color: #000;
	text-shadow: none;
}

::selection {
	background-color: #b3d4fc;
	/* Required when declaring ::selection */
	color: #000;
	text-shadow: none;
}


/* # =================================================================
	 # add
	 # ================================================================= */
a {
	transition: all .3s;
}

figure {
	margin: 0;
	padding: 0;

	img {
		max-width: 100%;
	}

	&.w100p {
		img {
			width: 100%;
		}
	}
}


table {
	border-collapse: collapse;
	border-spacing: 0;
}

ol,
ul {
	list-style: none;
}


/* # =================================================================
	 # disp
	 # ================================================================= */

.sp-disp{
	display: none;
}

@include mq-down(){
	.sp-disp{
		display: block;
	}

	.pc-disp{
		display: none;
	}
}