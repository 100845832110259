.page-privacy {
	.intro {
		text-align: center;

		@include mq-down(){
			text-align: left;
		}

		p {
			line-height: 2;
		}
	}


	.ulbox {
		padding: 0px 0px 0px 0px;
		font-size: 14px;
		margin: 0px 0px 5px 5px;

		li {
			padding: 5px 0px;
			list-style-type: disc;
			overflow: visible;
			list-style-position: inside;
		}
	}

	hr {
		border: 0;
		border-bottom: 1px solid #ddd;
		margin: 4rem auto;
	}

	.bg_green{
		padding:0 0 5rem;
	}
}