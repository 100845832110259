.page-sitemap{
	padding:0 0 5rem;

	li{
		width: calc(100% /2.2);
		margin-top: 1rem;

		@include mq-down(){
			width: 100%;
		}

	}

	a{
		position: relative;
		display: block;
		padding: 1.5rem 1rem;
		background:#fff;
		border:1px solid #ddd ;
		color:$black;
		font-size:1.7rem;
		text-align: center;
		transition:all .3s;

		// &:before{
		// 	position: absolute;
		// 	top: 50%;
		// 	left: -20px;
		// 	content: "";
		// 	width: 20%;
		// 	height: 1px;
		// 	background: $orange;
		// 	transition: all .5s;
		// }

		&:hover{
			// opacity:.7;
			background:$blue;
			color:#fff;

			&:before{
				left:0;
				width: 30%;
				height: 2px;
				background:$orange;
			}
		}
	}
}