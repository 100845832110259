.bread {
	display: none;
	position: relative;
	width: 100%;
	// margin-bottom: 3rem;
	padding: 20px 0;
	background:#3a3939;
	z-index: 0;
	// border-bottom: 1px solid #ccc;
	@media screen and (min-width: 960px) {
		display: block;
	}
	.bread_crumb {
		width: 96%;
		max-width: 1280px;
		margin: auto;
		text-align: left;
		li {
			display: inline;
			text-transform: uppercase;
			color: #fff;
			font-size: 1.4rem;
			&::after {
				content: '>';
				padding: 0 15px;
			}
			&:last-child::after {
				content: '';
			}
			a {
				color: #fff;
				font-size: 1.4rem;
			}
		}
	}
}