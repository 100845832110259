.list_asterisk{
	text-align: left;
	li{
		padding-left: 1.3em;
		text-indent: -1.3em;

		&:before{
			content:'※';
			margin-right: 5px;
		}
	}
}