.wp-pagenavi {
	margin-top: 50px;
	text-align: center;
	a, span {
		color: #333;
		background-color: #FFFFFF;
		padding: 8px 15px;
		margin: 0 2px;
		white-space: nowrap;
		transition: .2s ease-in-out;
		text-align: center;
		text-decoration: none;
		border: thin solid $blue;
	}
	a:hover, span.current {
		opacity:1;
		color: #fff;
		background-color: $blue;
	}
	@media screen and (max-width: 760px) {
		margin: 5vw 0;
		a, span {
			padding: 8px 2vw;
		}
	}
}

.page_nav{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 70px;
	@media screen and (max-width: 960px) {
		margin-bottom: 3vw;
	}

	a{
		color: $blue;
		background-color: #FFFFFF;
		padding: 8px 15px;
		margin: 0 2px;
		white-space: nowrap;
		transition: .2s ease-in-out;
		text-align: center;
		text-decoration: none;
		border: thin solid $blue;

		&:hover {
			opacity:1;
			color: #fff;
			background-color: $blue;
		}
	}
}