.page-company{
	.intro{
		padding: 9rem 1rem;

		@include mq-down(){
			padding:3rem .5rem;
		}

		.txt{
			p{
				line-height: 2;
			}
		}
	}



	.qualification{
		.flex{
			justify-content: space-between;
			align-items: flex-start;
			div{
				display: block;
				width:calc(100% / 2.2);
				p{
					margin-bottom: 1rem;
					text-align: center;
				}

			}
		}
	}
}