/* font setting */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
// @import url(//fonts.googleapis.com/css?family=Noto+Serif+JP&subset=japanese);

// @import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,700);
// .f_Josefin{
//  font-family: 'Josefin Sans', sans-serif;
// }

@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
.f_roboto{
 font-family: 'Roboto Condensed', sans-serif;
}

// @import url(https://use.fontawesome.com/releases/v5.5.0/css/all.css);