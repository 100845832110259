@charset "UTF-8";
@import "config/_config";
@import "config/_reset"; // org reset
@import "config/_font";


/*
# =================================================================
# COMMON
# =================================================================
*/
// /* overlay -----------------*/
// @import "module/_overlay";

/* background -----------------*/
@import "module/_background";

/* flex -----------------*/
@import "module/_flex";

/* bread -----------------*/
@import "module/_bread";

// /* panel -----------------*/
// @import "module/_panel";

// /* btn -----------------*/
// @import "module/_btn";

/* margin -----------------*/
@import "module/_margin";

// /* table -----------------*/
@import "module/_table";

/* split -----------------*/
@import "module/split";

/* pagenavi -----------------*/
@import "module/_pagenav";

/* gmap -----------------*/
@import "module/_gmap";

/* privacy box -----------------*/
@import "module/_privacy_box";

// /* slant -----------------*/
// @import "module/_slant";

/* ttl -----------------*/
@import "module/_ttl";

/* list -----------------*/
@import "module/_list";

/* other -----------------*/
@import "module/_other";



// /* box -----------------*/
// @import "module/_box";

// /* tab-btn -----------------*/
// @import "module/_tab-btn";


/*
# =================================================================
# LAYOUT
# =================================================================
*/
// /* container -----------------*/
// @import "layout/_container";

// /* header -----------------*/
// @import "layout/_header";

// /* footer -----------------*/
// @import "layout/_footer";

// /* gnav -----------------*/
// @import "layout/_gnav";

// /* kv -----------------*/
// @import "layout/_kv";


/*
# =================================================================
# PAGES
# =================================================================
*/
/* Overall top -----------------*/
@import "page/_lower";
@import "page/_service";
@import "page/_work";
// @import "page/_staff";
@import "page/_news";
@import "page/_company";
@import "page/_diagnosis";
@import "page/_privacy";
@import "page/_sitemap";
@import "page/_contact";
