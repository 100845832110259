.lowermainbox-wrap{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 400px;
	background: $blue;
	background-size: cover;
	background: url(../images/top/bg_about.jpg) no-repeat center 74%;
  background-size: cover;
  overflow: hidden;

  // &:before{
  // 	position: absolute;
		// top:0;
  // 	left:0;
  // 	transform:rotate(-45deg);
  // 	content: "";
  // 	width: 30%;
  // 	height: 100%;
  // 	background: $blue;
  // }

	h2{
		// color:#fff;
		font-size: 4rem;
		font-weight: bold;
		font-family: 'Montserrat', sans-serif;
		text-transform: uppercase;
		line-height: 1.5;
		span{
			display: block;
			font-size: 2rem;
		}

		@include mq-down(){
			font-size: 2.5rem;
		}
	}
}



.subcontents{
	&-inner{
		max-width: 1040px;
		padding: 5rem 0;
		text-align: left;

		@include mq-down(){
			width: 96%;
		}
	}
}