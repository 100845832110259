.ttl-border_twocolor {
	position: relative;
	margin-bottom: 1.5rem;
	border-bottom: solid 3px $blue;
	text-align: left;
	font-size: 2.6rem;
	line-height: normal;

	&:after {
		position: absolute;
		content: " ";
		display: block;
		border-bottom: solid 3px $orange;
		bottom: -3px;
		width: 20%;
	}


	@include mq-down() {
		font-size: 2rem;
	}
}




.ttl-center {
	position: relative;
	margin-top: 5rem;
	margin-bottom: 1em;
	font-size: 2.6rem;
	line-height: normal;
	text-align: center;
	color: $blue;

	&:before {
		content: '';
		position: absolute;
		bottom: -15px;
		display: inline-block;
		width: 60px;
		height: 5px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		background-color: $blue;
		border-radius: 2px;
	}

	@include mq-down() {
		font-size: 2rem;
	}
}