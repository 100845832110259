.page-work{

	&.archive{
		.flex{
			justify-content: space-between;
			margin-bottom: 2rem;
		}

		.item{
			position: relative;
			width: calc(100% / 2.1);
			padding: 2rem;
			border:4px solid $blue;
			// &:before{
			// 	position: absolute;
			// 	top:50%;
			// 	left:calc(50% - 15px);
			// 	font-family: "Font Awesome 5 Free";
			// 	content: "\f0a9";
			// 	font-weight: 900;
			// 	color:$blue;
			// 	font-size: 5rem;

			// 	@include mq-down(){
			// 		top: 47%;
			// 		content: "\f0ab";
			// 		font-size: 3rem;
			// 	}
			// }

			@include mq-down(){
				width: 100%;
				&:nth-of-type(n+2){
				margin-top: 4rem;
			}
			}


			&:nth-of-type(n+6){
				margin-top: 1.5%;
			}

			&:nth-of-type(n+3){
				margin-top: 4rem;
			}


			figure{
				margin: 0 61px;
				overflow: hidden;
				width: 300px;
				height: 200px;
				position: relative;

				img{
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					-ms-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					width: 100%;
					height: auto;
				}


				@include mq-down(){
					margin: 0 auto;


					& + figure{
						margin-top: 5rem;
					}
				}
			}

			.ttl{
				padding-bottom: 5px;
				margin-bottom: 2rem;
				color:$blue;
				font-weight: bold;
				border-bottom: 1px solid $blue;
				@include fz(20);
			}
		}
	}


	// &.detail{
	// 	h3{
	// 		margin-bottom: 1.5rem;
	// 		text-align: center;
	// 		font-size:3rem;
	// 		color:$green;
	// 		font-weight: bold;

	// 		& + p{
	// 			margin-bottom: 3rem;
	// 			text-align: center;
	// 		}

	// 		@include mq-down(){
	// 			font-size: 2rem;
	// 		}
	// 	}
	// }
}