/* Alignment setting */
.ta-c{
	text-align: center;
}

.ta-l{
	text-align: left;
}

.ta-r{
	text-align: right;
}


.bg01{
	background-color: #f6f9ee;
}


// /* Font setting */
.c_red{
	color:$red;
}

// .c_blue{
// 	color:$blue;
// }

.c_orange{
	color:$orange;
}

// .c_green{
// 	color:$green!important;
// }


// /* background setting*/
// .bg_white{
// 	padding: 30px;
// 	background-color:#fff;
// }

// .bg_gray{
// 	background-color:$gray;
// }

// .bg_blue{
// 	background-color:$sBlue;
// }

// .bg_sorange{
// 	background-color:#F6EEEB;
// }
