.page-service{
	.intro{
		text-align: center;


		@include mq-down(){
			text-align: left;
		}
	}




	.strength{
		margin-top: 8rem;

		.flex{
			+ .ttl-border_twocolor{
				margin-top: 6rem;
			}
		}



		.btn01{
			margin-top: 2rem;
		}
	}


	.area{
		.tbl02{
			margin-top: 2rem;
		}


		.txt{
			margin-top: 2rem;
			padding: 3rem;
			background: #fff;
			border:double 4px $blue;

			h4{
				margin-bottom: 1.5rem;
				color:$orange;
				text-align: center;
				font-size: 2.2rem;
				font-weight: bold;
			}

			small{
				display: block;
				margin-top: 1rem;
			}
		}
	}



}