.privacyBox {
	padding: 0px 20px 30px;
	width: calc(100% - 0px);
	height: 300px;
	overflow-y: scroll;
	margin-bottom: 20px;
	background: #fff;
	font-size: 90%;
	border: 1px solid #ddd;
	.sub_ttl {
		margin: 30px 0px 15px 0px;
		font-size: 120%;
		padding: 2px 0px 2px 8px;
		border-left: 5px solid $green;
	}
	p {
		margin-bottom: 10px;
	}
	ul.ulbox li {
		list-style-type: disc;
		list-style-position: inside;
		margin-left: 10px;
	}
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #ddd;
	}
	&::-webkit-scrollbar-thumb {
		background: $green;
	}
}


#privacy a{
	color:$blue;
	text-decoration: underline;
}

// .agrBox{}

// .contact_submitBtn{
// 	text-align: center;
// 	input{
// 		width: 200px;
// 		margin-top: 30px;
// 		padding: 10px 0;
// 		color:#fff;
// 		font-weight: bold;
// 		background-color:$green;
// 		border:0;
// 		font-size:18px;
// 		cursor: pointer;
// 		transition:all .3s;

// 		&:hover{
// 			opacity:.7;
// 		}
// 	}
// }